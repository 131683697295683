#TermsAndConditions ol {
  counter-reset: item
}
#TermsAndConditions li {
  display: block
}
#TermsAndConditions li:before {
  content: counters(item, ".") ". ";
  counter-increment: item
}
.term-and-condition ul li{
  display: list-item;
}
  .term-and-condition li{
display: block;
}
  .term-and-condition ul{
  list-style-type: disc;
}