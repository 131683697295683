@import url('https://fonts.googleapis.com/css?family=Noto+Sans+Display');
body {
  margin: 0;
  font-family: 'Noto Sans Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rcw-conversation-container > .rcw-header {
  background-color: #F5FBFC;
  color: #0099CC;
  padding-left: 10px;
  padding-right: 10px;
}

.rcw-message > .rcw-response {
  /* background-color: black;
  color: white; */
}

.rcw-picker-btn {
  display: none;
}

.rcw-new-message {
  width: 100%;
}

.rcw-send {
  margin-left: 10px;
  margin-bottom: 2px;
}

.rcw-launcher {
  background-color: #0099CC;
}

.rcw-client .rcw-message-text {
  background-color: #0099CC;
  color: #F5FBFC;
}

.rcw-response .rcw-message-text {
  max-width: 250px;
}

.rcw-widget-container {max-width: 500px;}

.rcw-messages-container {width: 500px; min-width: 0;}

::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #8C8C8F;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #8C8C8F;
}